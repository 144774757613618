import { Text, Grid, Stack, createStyles, Image } from '@mantine/core';
import { TablerIcon } from '@tabler/icons';
import { useMediaQuery } from '@mantine/hooks';

import React from 'react';

const iconHeight = 72;

const useStyles = createStyles(() => ({
  iconContainer: {
    minHeight: `${iconHeight}px`,
  },
  paperWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface InformationItem {
  Title: string;
  Text: string;
  Icon: TablerIcon;
  Image?: string;
}

interface CardGridProps {
  Items: InformationItem[];
  HasBox?: boolean;
  TextColor?: string;
}

export const CardGrid2: React.FC<CardGridProps> = ({ Items, TextColor }) => {
  const { classes, theme } = useStyles();
  const smallScreen = useMediaQuery('(max-width: 992px)');
  
  const defaultTextColor = TextColor ?? theme.black;

  const getTexts = (item: InformationItem) => {
    return (
      <>
        <Text weight={700} size={'xl'} pt={0} color={defaultTextColor}>
          {item.Title}
        </Text>
        <Text size={'md'} color={defaultTextColor}>
          {item.Text}
        </Text>
      </>
    );
  };

  const handleText = (item: InformationItem) => {
    return getTexts(item);
  };

  return (
    <Grid gutter={smallScreen ? 60 : 100} m={0} w={'100%'} align={'center'}>
      {Items.map((item, index) => {
        const firstCol = index * 2;
        const imageLeft = index % 2 === 0;

        const imageOrder = imageLeft ? firstCol : firstCol + 1;
        const textOrder = imageLeft ?  firstCol + 1 : firstCol;
        
        return (
          <>
            <Grid.Col key={item.Title + index + '_index'} sm={12} md={4} order={firstCol + 1} orderMd={imageOrder} >
              <Stack align={'center'} h={'100%'} spacing={0}>
                <div style={{ minHeight: '96px' }}>
                  <Image maw={500} src={item.Image!} style={{ filter: 'grayscale(70%)' }} radius={8}/>
                </div>
              </Stack>
            </Grid.Col>
            <Grid.Col key={item.Title + index + '_text'} sm={12} md={8} order={firstCol} orderMd={textOrder}>
              {handleText(item)}
            </Grid.Col>
          </>
        );
      })}
    </Grid>
  );
};
