import { LinkItem } from '../../components/PageHeader';

export const PageLinks: LinkItem[] = [
  { link: '#section-1', label: 'Om Grantme', newTab: false },
  { link: '#section-2', label: 'Process', newTab: false },
  { link: '#section-3', label: 'Funktioner', newTab: false },
  { link: '#section-4', label: 'Kontakt', newTab: false },
  { link: '#section-5', label: 'Om oss', newTab: false },
  { link: 'https://admin.grantme.se', label: 'Logga in', newTab: true },
];
