import { Text, Grid, Stack, createStyles, Paper } from '@mantine/core';
import { TablerIcon } from '@tabler/icons';

import React from 'react';

const iconHeight = 72;

const useStyles = createStyles((theme) => ({
  iconContainer: {
    minHeight: `${iconHeight}px`,
  },
  paperWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export interface InformationItem {
  Title: string;
  Text: string;
  Icon: TablerIcon;
  Image?: string;
}

interface CardGridProps {
  Items: InformationItem[];
  HasBox: boolean;
  IconColor?: string;
  TextColor?: string;
}

export const CardGrid: React.FC<CardGridProps> = ({ Items, HasBox, IconColor, TextColor }) => {
  const { classes, theme } = useStyles();

  const defaultIconColor = IconColor ?? theme.colors.blue[7];
  const defaultTextColor = TextColor ?? theme.black;

  const getTexts = (item: InformationItem) => {
    return (
      <>
        <Text weight={700} pt={12} color={defaultTextColor}>
          {item.Title}
        </Text>
        <Text size={'sm'} align={'center'} color={defaultTextColor}>
          {item.Text}
        </Text>
      </>
    );
  };

  const handleText = (item: InformationItem) => {
    if (HasBox) {
      return (
        <div className={classes.paperWrapper}>
          <Paper
            bg={defaultIconColor}
            className={classes.paper}
            pb={12}
            mt={8}
            radius={0}
            pr={16}
            pl={16}>
            {getTexts(item)}
          </Paper>
        </div>
      );
    }

    return getTexts(item);
  };

  return (
    <Grid gutter={HasBox ? 60 : 100} m={0} w={'100%'}>
      {Items.map((item, index) => {
        return (
          <Grid.Col key={item.Title + index} md={4} sm={6}>
            <Stack align={'center'} h={'100%'} spacing={0}>
              <div style={{ minHeight: '96px' }}>
                <item.Icon stroke={0.8} size={iconHeight} color={defaultIconColor} />
              </div>
              {handleText(item)}
            </Stack>
          </Grid.Col>
        );
      })}
    </Grid>
  );
};
