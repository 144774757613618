/* eslint-disable @typescript-eslint/indent */
import { DefaultMantineColor } from '@mantine/core';

// base '#042336'

export const customColors: Partial<
  Record<
    DefaultMantineColor,
    [string, string, string, string, string, string, string, string, string, string]
  >
> = {
  gray: [
    '#f5f7fa',
    '#eff2f6',
    '#e3e6ed',
    '#cbd0dd',
    '#9fa6bc',
    '#8a94ad',
    '#6e7891',
    '#525b75',
    '#3e465b',
    '#31374a',
  ],
  dark: [
    '#ffeeef',
    '#ded5db',
    '#c3bcc1',
    '#aaa3a6',
    '#90888d',
    '#776f73',
    '#5d565a',
    '#443d41',
    '#2c2426',
    '#130913',
  ],
  orange: [
    '#fff5dd',
    '#f9e1b6',
    '#f3cc8a',
    '#ecb85e',
    '#e7a432',
    '#cd8a18',
    '#a06b11',
    '#734d09',
    '#452e01',
    '#1c0e00',
  ],
  pink: [
    '#ffe6f3',
    '#f9b9d7',
    '#f28bbb',
    '#ed5ea0',
    '#e83587',
    '#ce1f6d',
    '#a11755',
    '#730f3d',
    '#460624',
    '#1b000d',
  ],
  cyan: [
    '#d8fff7',
    '#acfff1',
    '#7dffed',
    '#4dffeb',
    '#27ffed',
    '#17e6dc',
    '#00b3b0',
    '#008076',
    '#004e42',
    '#001c15',
  ],
  blue: [
    '#e1f6ff',
    '#b8e1fa',
    '#8cccf4',
    '#61b7ef',
    '#3ca3ea',
    '#2a8ad1',
    '#1e6ca2',
    '#134d75',
    '#052e47',
    '#00101c',
  ],
  green: [
    '#dffeef',
    '#baf3d9',
    '#93eac5',
    '#6ae1b2',
    '#44d9a2',
    '#2bbf8d',
    '#1e9572',
    '#116a4b',
    '#034128',
    '#00170a',
  ],
  red: [
    '#ffe8ec',
    '#f2c2c9',
    '#e49aa5',
    '#d87381',
    '#cd4c5f',
    '#b33245',
    '#8c2735',
    '#651b26',
    '#3e0e17',
    '#1a0207',
  ],
  yellow: [
    '#fffddf',
    '#fcf9b3',
    '#faf985',
    '#f8ec56',
    '#f6d92a',
    '#dcb316',
    '#ab800d',
    '#7a5205',
    '#492c00',
    '#180d00',
  ],
};
