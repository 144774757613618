import { Box, Button, Container, createStyles, Flex, Text } from '@mantine/core';
import { IconCookie } from '@tabler/icons';
import React from 'react';
import CookieConsent, { OPTIONS } from 'react-cookie-consent';
import { t } from 'ttag';
//import { formPageBuilderStyles } from '../formbuilder/FormBuilderStyles/formPageBuilderStyles';

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors[theme.primaryColor][8],
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    //justifyContent: 'space-between',
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 999,
    bottom: 0,
    paddingBottom: '24px',
  },
  button: {
    padding: '16px 24px',
    fontFamily: theme.fontFamily,
    color: theme.white,
    backgroundColor: theme.colors[theme.primaryColor][8],
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.defaultRadius,
    fontSize: '16px',
    fontWeight: 500,
  },
}));

export const LiitCookieConsent: React.FC = () => {
  const { classes } = useStyles();

  return (
    <CookieConsent
      cookieName={'grantme-cookie-consent'}
      buttonText={t`Godkänn cookies`}
      disableStyles={true}
      location={OPTIONS.BOTTOM}
      buttonClasses={classes.button}
      containerClasses={classes.container}
      ButtonComponent={(button) => (
        <Button mt={16} onClick={() => button.onClick()}>
          {button.children}
        </Button>
      )}
      contentClasses="text-capitalize">
      <Container mb={20}>
        <Flex align={'center'}>
          <Box w={56} h={56} mr={24}>
            <IconCookie size={56} color={'white'} />
          </Box>
          <Text color={'white'}>
            Vi och våra partners använder cookies för att få den här webbplatsen att fungera och för
            att anpassa innehåll.
          </Text>
        </Flex>
      </Container>
    </CookieConsent>
  );
};
