import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageNotFoundView } from './features/not-found/PageNotFoundView';
import { StartViewNew } from './features/landing-page/StartViewNew';
import { PriceList } from './features/price-list/PriceList';

export const Router: FC = () => (
  <Routes>
    <Route path="/priceList" element={<PriceList />} />
    <Route path="/" element={<StartViewNew />} />
    <Route path="*" element={<PageNotFoundView />} />
  </Routes>
);
