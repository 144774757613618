import { initLocale } from './i18nInit';
import { Router } from './router';
import { LiitCookieConsent } from './components/LiitCookieConsent/LiitCookieConsent';
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  MantineThemeOverride,
} from '@mantine/core';
import { useState } from 'react';
import { customColors } from './utils/customColors';

const fontFamilyInter = `${"'Inter Tight', sans-serif"}`;
export const BORDER_COLOR = '#cbd0dd'; //gray[3]

const App = () => {
  initLocale();

  console.log('Grantme AB ' + new Date().getFullYear());

  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  const customTheme: MantineThemeOverride = {
    colorScheme,
    colors: customColors,
    primaryColor: 'blue',
    primaryShade: { light: 5, dark: 4 },
    defaultRadius: 'sm',
    defaultGradient: {
      from: 'orange',
      to: 'red',
      deg: 45,
    },
    fontFamily: fontFamilyInter,
    headings: { fontFamily: `${"'Exo 2', sans-serif"}` },
    datesLocale: 'sv',
    components: {
      Accordion: {
        styles: (theme) => ({
          label: {
            fontSize: theme.fontSizes.md,
            color: theme.colors.gray[9],
            fontWeight: 600,
            // '&[data-active]': {
            //   color: theme.colors.gray[4],
            // },
          },
        }),
      },
      Tabs: {
        styles: (theme) => ({
          tab: {
            color: theme.colors[theme.primaryColor][7],
            fontWeight: 600,
            '&[data-active]': {
              color: theme.colors[theme.primaryColor][7],
            },
          },
          tabsList: {
            border: '0px',
          },
        }),
      },
      TextInput: {
        styles: (theme) => ({
          label: {
            color: theme.colors[theme.primaryColor][7],
            fontWeight: 'bold',
            display: 'inline-flex',
          },
        }),
      },
      InputWrapper: {
        styles: (theme) => ({
          label: {
            color: theme.colors[theme.primaryColor][7],
            fontWeight: 'bold',
            display: 'inline-flex',
            gap: '8px',
            width: '100%',
          },
        }),
      },
      Table: {
        styles: (theme) => ({
          root: {
            thead: {
              tr: {
                th: {
                  background: theme.colors.gray[1],
                  color: theme.colors.gray[9],
                  borderBottom: `1px solid ${BORDER_COLOR}`,
                },
              },
            },
            tbody: {
              tr: {
                td: {
                  borderTop: `1px solid ${BORDER_COLOR}`,
                },
              },
            },
          },
        }),
      },

      Paper: {
        styles: (theme) => ({
          root: {
            ['@media print']: {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          },
        }),
      },

      Button: {
        styles: {
          label: {
            textTransform: 'uppercase',
          },
          root: {
            ['@media print']: {
              display: 'none',
            },
          },
        },
      },

      ActionIcon: {
        styles: {
          root: {
            ['@media print']: {
              display: 'none',
            },
          },
        },
      },

      Title: {
        styles: (theme) => ({
          root: {
            color: theme.colors.gray[9],
            //textTransform: 'uppercase',
            fontFamily: fontFamilyInter,
            ['@media print']: {
              color: theme.black,
              fontFamily: fontFamilyInter,
            },
          },
        }),
      },

      Text: {
        styles: (theme) => ({
          root: {
            ['@media print']: {
              color: theme.black,
              fontFamily: fontFamilyInter,
            },
          },
        }),
      },
    },
  };

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={customTheme} withGlobalStyles withNormalizeCSS withCSSVariables>
        <Router />
        <LiitCookieConsent />
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
